/**
 * Settings for Axios Http Api calls.
 * Create a copy of this file with name Request.js and enter config there.
 * Request.js file is not tracked in .git
 */

// Base url of backend app
// export const APP_BASE_URL = "http://localhost:8000/";
export const APP_BASE_URL = "https://backend.tibit.me/";

// Base url of backend api
// export const API_BASE_URL = "http://localhost:8000/admin/";
export const API_BASE_URL = "https://backend.tibit.me/admin/";

// Base url of frontend app
// export const FRONT_BASE_URL = "http://localhost:3000/";
export const FRONT_BASE_URL = "https://admin.tibit.me";

export const AWS_ACCESS_KEY_ID = "AKIATCKAMWTVBETZBSHM";
export const AWS_SECRET_ACCESS_KEY = "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";
export const AWS_BUCKET = "tibit";
export const AWS_DEFAULT_REGION = "eu-north-1";
export const AWS_URL = "https://tibit.s3";
export const AWS_DOMAIN = "amazonaws.com";
